import { Link } from "react-router-dom";
import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import DataTable from "./DataTable";
import L from "./Lang";
import LoadingAlert from "./LoadingAlert";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import AppFormActions from "./AppFormActions";
import Icon from "./Icon";

class EODList extends BaseCom {
    componentDidMount() {
        NavMenuGlobal.setTitle('EOD');
        qreq.get('/api/eod/list', j => {

            if(j.list) {
                this.setState({ list: j.list });
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    render() {
        if(!this.state.list)
            return <LoadingAlert />
        return <div className="container-fluid">
            <DataTable columns={[
                {
                    name: 'close_date', title: <L>Date</L>, valueFunc: row => {
                        if(row.close_date === 'Current')
                            return <Link to={'/console/eod/current'}>Current</Link>
                        else
                            return <Link to={'/console/eod/' + row.batch_id}>{Globals.toMDYLocalDateString(row.close_date)}</Link>
                    }
                }
            ]} data={this.state.list} />
            <AppFormActions showBackButton>
                <Link to="/console/eod/current" className="btn btn-primary btn-lg"><Icon icon="faFile" /> <L>New EOD</L></Link>
            </AppFormActions>
        </div>;
    }
}

export default withParamsAndNavigate(EODList);