import { Link } from "react-router-dom";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import DataTable from "./DataTable";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import Icon from "./Icon";
import Tick from "./Tick";
import AppFloatingActions from "./AppFloatingActions";
import AppTopTools from "./AppTopTools";

class CustomerList extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            search: null,
            lastSearch: null
        };
        this.load = this.load.bind(this);
        this.tick = this.tick.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    load() {
        this.fillList('/api/customer/list', 'list');
    }

    tick(e) {
        if (this.state.search !== this.state.lastSearch) {
            this.setState({ lastSearch: this.state.search });
            this.fillList('/api/customer/list?search=' + this.state.search, 'list');
        }
    }

    render() {
        return <div className="container-fluid">
            <AppFloatingActions>
                <Link to="/console/customer/new" role="button" className="btn btn-primary btn-lg"><Icon icon="faPlus" /></Link>
            </AppFloatingActions>
            <Tick onTick={this.tick} />
            <AppTopTools>
                <input type="text" className="form-control mb-3" placeholder="Search" onChange={e => this.setState({ search: e.target.value })} />
            </AppTopTools>
            <div className="overflow-hidden">
                <DataTable columns={[
                    {
                        title: 'Customer', name: 'cust_name', valueFunc: row => {
                            return <Link to={'/console/customer/' + row.id}>{row.cust_name}</Link>;
                        }
                    },
                    { title: 'Phone', name: 'phone', className: 'd-none d-md-table-cell' },
                    { title: 'Email', name: 'email', className: 'd-none d-md-table-cell' }
                ]} data={this.state.list ?? []} />
            </div>
        </div>;
    }
}

export default withParamsAndNavigate(CustomerList);