import React from "react";
import Auth from "../shared/Auth";
import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import FormLabel from "./FormLabel";
import Icon from "./Icon";
import { ImageView } from "./ImageView";
import L from "./Lang";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import { Pop } from "./PopModal";
import './ItemView.css';

class ItemView extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            item: null,
            info: {
                quantity: 1
            },
            uomList: []
        };
        this.uploadRef = React.createRef();
        this.uploadCameraRef = React.createRef();
        this.load = this.load.bind(this);
        this.submit = this.submit.bind(this);
        this.checkInv = this.checkInv.bind(this);
        this.getPrice = this.getPrice.bind(this);
        this.getDiscountedPrice = this.getDiscountedPrice.bind(this);
        this.getDiscount = this.getDiscount.bind(this);
        this.checkPromo = this.checkPromo.bind(this);
    }

    componentDidMount() {
        Auth.getLogin(u => {
            this.setState({ user: u });
        });
        this.load();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.item !== this.props.item)
            this.load();
    }

    load() {
        this.setState({ item: null });
        if (this.props.item) {
            qreq.get('/api/item/item?id=' + this.props.item.id + '&customerID=' + this.props.customerID, j => {
                if (j.item) {
                    var uomList =  [
                        { id: 'stock_uom', name: j.item.stock_uom + Globals.getLang(' of ') + j.item.pack_desc, uom: j.item.stock_uom },
                    ];

                    if(j.item.alt_1_uom && j.item.alt_1_type === '1') uomList.push({ id: 'alt_1_uom', name: j.item.alt_1_uom + (j.item.alt_1_mult ? Globals.getLang(' of ') + j.item.alt_1_mult : ''), uom: j.item.alt_1_uom });
                    if(j.item.alt_2_uom && j.item.alt_2_type === '1') uomList.push({ id: 'alt_2_uom', name: j.item.alt_2_uom + (j.item.alt_2_mult ? Globals.getLang(' of ') + j.item.alt_2_mult : ''), uom: j.item.alt_2_uom });
                    if(j.item.alt_3_uom && j.item.alt_3_type === '1') uomList.push({ id: 'alt_3_uom', name: j.item.alt_3_uom + (j.item.alt_3_mult ? Globals.getLang(' of ') + j.item.alt_3_mult : ''), uom: j.item.alt_3_uom });

                    this.setState({ item: j.item, uomList: uomList });
                    var uom = null;

                    let info = {...this.state.info};

                    if(this.props.item.orderUOM) {
                        var find = uomList.find(u => u.id === this.props.item.orderUOM);
                        if(find)
                            uom = find.id;
                    }
                    else if(uomList.length > 1) {
                        uom = 'stock_uom';
                        info.uom = uom;
                    }
                    if (this.props.item.quantity)
                    {
                        info.quantity = this.props.item.quantity;
                        info.originalQuantity = this.props.item.quantity;
                        info.message = this.props.item.message;
                        info.uom = this.props.item.uom;
                    }
                    if(this.props.item.isCustomPrice)
                    {
                        info.customPrice = this.props.item.customPrice;
                        info.price = this.props.item.customPrice;
                        info.isCustomPrice = true;
                        this.setState({ showChangePrice: true });
                    }
                    this.setState({ info: info });
                        
                    this.checkInv(j.item.id);
                    this.checkPromo(j.item.id);
                }
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback);
        }
    }

    checkInv(itemID) {
        qreq.get('/api/inventory/onhand?itemID=' + itemID, j => {
            if (j.item === true)
                this.setState({ invCount: true });
            else if (j.item === false || j.item === 0)
                this.setState({ invMessage: <L>This item is unavailable.</L>, invCount: 0 });
            else if (typeof (j.item) === 'number' && j.item > 0)
                this.setState({ invCount: j.item });
        });
    }

    checkPromo(itemID) {
        qreq.get('/api/promo/listby/item?itemID=' + itemID + '&customerID=' + this.props.customerID, j => {
            if(j.list)
                this.setState({ promos: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    submit(e) {
        if (e) e.preventDefault();
        var info = {...this.state.info};
        if(info.uom) {
            var find = this.state.uomList.find(u => u.id === info.uom);
            if(find)
                info.orderUOM = find.id;
        }
        info.price = info.isCustomPrice ? info.customPrice : this.getDiscountedPrice();
        info.discount = this.getDiscount();
        /*
        if(this.state.invCount === false) {
            this.alert(<L>This item is unavailable.</L>);
            return;
        }
        else if(this.state.invCount !== true && this.state.invCount < info.quantity) {
            this.alert(<L>Not enough stock on hand.</L>);
            return;
        }
        */
        if (this.props.onAddItem)
            this.props.onAddItem({ ...this.state.item }, info);
        else if (this.props.onEditItem)
            this.props.onEditItem({ ...this.state.item }, info);
    }

    getPrice() {
        var price = this.state.item.price;
        if(this.state.info?.uom === 'alt_1_uom' && this.state.item?.alt_1_mult)
            price = Number(price) * Number(this.state.item.alt_1_mult);
        else if(this.state.info?.uom === 'alt_2_uom' && this.state.item?.alt_2_mult)
            price = Number(price) * Number(this.state.item.alt_2_mult);
        else if(this.state.info?.uom === 'alt_3_uom' && this.state.item?.alt_3_mult)
            price = Number(price) * Number(this.state.item.alt_3_mult);
        return price;
    }


    getDiscount() {
        return this.getPrice() - this.getDiscountedPrice();
    }

    getDiscountedPrice() {
        // exit when custom price
        if(this.state.info.isCustomPrice)
            return this.state.info.customPrice;


        var price = this.getPrice();
        var discountedPrice = price;
        if(this.state.promos) {
            this.state.promos.forEach(promo => {
                if(promo.promo_type === '1') {
                    discountedPrice = Number(price) - (Number(price) * Number(promo.amount) / 100);
                }
                else if(promo.promo_type === '2') {
                    discountedPrice = Number(price) - Number(promo.amount);
                }
            });
        }
        return discountedPrice;
    }

    changePrice = () => {
        if(this.state.showChangePrice)
            this.setState({ info: {...this.state.info, customPrice: null, isCustomPrice: false }, showChangePrice: false });
        else
            this.setState({ info: {...this.state.info, customPrice: this.getPrice(), isCustomPrice: true }, showChangePrice: true });
    }

    triggerUploadImage = () => {
        this.uploadRef.current.click();
    }

    triggerUploadCameraImage = () => {
        this.uploadCameraRef.current.click();
    }

    uploadImage = (e) => {
        const file = e.target.files[0];
        const f = () => {
            if (!file)
                return;
            var reader = new FileReader();
            reader.onload = (e) => {
                var data = e.target.result;
                this.setState({ imageData: data, uploading: true });
                qreq.post('/api/image/upload', { modelID: this.props.item.id, base64Content: data.substring(data.indexOf(',') + 1), mimeType: file.type, name: file.name }, j => {
                    if (j.errorCode === 0) {
                        this.setState({ imageURL: j.item.imageURL });
                        this.props?.onImageUpload(j.item);
                    }
                    else {
                        this.alert(j.errorMessage);
                    }
                    this.setState({ uploading: false, imageData: null });
                }, () => {
                    this.unkownErrorCallback();
                    this.setState({ scanning: false });
                });
            };
            reader.readAsDataURL(file);
        }

        if(this.props.item.imageURL && this.props.item.imageURL !== '/assets/img/placeholder.png') {
            Pop.showConfirm(<L>This will replace the existing image. Are you sure you want to upload this image?</L>, 'Replace Image', f);
        }
        else {
            f();
        }
        e.target.value = '';
    }

    getImageUrl = () => {
        if(this.state.imageURL)
            return Globals.serverUrl + this.state.imageURL;
        else if(this.state.imageData)
            return this.state.imageData;
        return Globals.serverUrl + this.props.item.imageURL;
    }

    render() {
        return <div className="container-fluid">
            <input type="file" ref={this.uploadRef} style={{ display: 'none' }} onChange={this.uploadImage} accept="image/*" />
            <input type="file" ref={this.uploadCameraRef} style={{ display: 'none' }} onChange={this.uploadImage} accept="image/*" capture="camera" />
            <form onSubmit={this.submit}>
                {this.props.item ? <>
                    <Card title={this.props.item.desc_1}>
                        <div className="d-flex justify-content-center mb-3">
                            <div className="view-img-wrapper">
                                {this.state.user?.user3?.canUploadImages && <div  className="view-img-upload">
                                    <div role="button" title={Globals.getLang('Take Photo')} onClick={this.triggerUploadCameraImage} className="d-block d-lg-none"><Icon icon="faCamera" /></div>
                                    <div role="button" title={Globals.getLang('Upload Image')} onClick={this.triggerUploadImage}><Icon icon="faUpload" /></div>
                                </div>}
                                <img
                                    src={this.getImageUrl()}
                                    alt=""
                                    className="img-fluid view-img"
                                    onClick={() => ImageView.show(Globals.serverUrl + this.props.item.imageURL)}
                                    />
                            </div>
                        </div>
                        <div className="text-center mb-3">
                            <L>Item #:</L> <strong>{this.state.item?.item_num}</strong>
                        </div>
                        {this.state.item ? <>
                            <div className="row">
                                <div className="col">
                                    {this.state.uomList.length > 1 ? <FormInput model={this.state.info} type="select" name="uom" label="Pack Description" selectDefault="UOM" options={this.state.uomList} required onChange={() => this.setState({ info: {...this.state.info}})} inputClassName="bg-highlight" /> : <FormLabel value={<>
                                    {this.state.item.stock_uom} <L>of</L> {this.state.item.pack_desc}</>} label="Pack Description" />}
                                </div>
                                <div className="col">
                                    <FormLabel value={this.state.invCount === true ? 'Available' : this.state.invCount === 0 ? 'Unavailable' : this.state.invCount} label="On Hand" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {!this.state.showChangePrice ? <FormLabel value={Globals.formatPrice(this.getPrice())} label="Price" postLabel={this.state.user?.user3.canChangePrice && <small role="button" className="badge text-light bg-primary" onClick={this.changePrice}>change price</small>} /> : <h5>Price <small role="button" className="badge text-light bg-danger" onClick={this.changePrice}>cancel</small></h5>}
                                    
                                    {this.state.showChangePrice && <FormInput model={this.state.info} name="customPrice" label="" type="number" step="0.01" onChange={() => this.setState({ info: { ...this.state.info } })} noHelpers preInput="$" />}
                                </div>
                                {this.state.promos?.length ? <div className="col">
                                    <FormLabel value={Globals.formatPrice(this.getDiscountedPrice())} label="Discounted Price" />
                                </div> : null}
                                <div className="col">
                                    <FormLabel value={Globals.formatPrice(Number(this.getDiscountedPrice()) * Number(this.state.info.quantity))} label="Sub-Total" />
                                </div>
                            </div>
                        </> : null}
                        <FormInput model={this.state.info} name="quantity" label="Quantity" type="number" step="1" onChange={() => this.setState({ info: { ...this.state.info } })} />
                        {this.state.promos?.length ? <div className="mb-3">
                            <label className="form-label">Promotions</label>
                            <table className="table">
                                <tbody>
                                    {this.state.promos.map((promo, i) => <tr key={i}>
                                        <td style={{width: '80px'}} className="small">{promo.promo_type === '1' ? promo.amount + '%' : promo.promo_type === '2' ? Globals.formatPrice(promo.amount)
                                            : promo.promo_type === '7' || promo.promo_type === '8' ? promo.p_from + '-' + promo.p_to : null}</td>
                                        <td>
                                            {promo.desc_1}
                                            {promo.promo_type === '7' || promo.promo_type === '8' ? <small className="d-block text-muted"><L>Volume based promotions are calculated in order total.</L></small> : null}
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div> : null}
                        <FormInput model={this.state.info} name="message" label="Message (Optional)" type="text" onChange={() => this.setState({ info: { ...this.state.info } })} labelAsPlaceholder />
                    </Card>
                </>
                    : <LoadingAlert />}
                <div className="my-3 d-flex gap-1 app-form-actions">
                    <div>
                        <button type="button" className="btn btn-secondary btn-lg" onClick={this.props.onCancel}><Icon icon="faArrowLeft" /> <L>Back</L></button>
                    </div>
                    <div className="flex-fill text-end">

                        {this.props.onAddItem ? <button type="submit" className="btn btn-primary btn-lg" disabled={!this.state.item}><Icon icon="faSave" /> <L>Add to Order</L></button>
                            : this.props.onEditItem ? <button type="submit" className="btn btn-primary btn-lg" disabled={!this.state.item}><Icon icon="faSave" /> <L>Save Changes</L></button>
                                : null}
                    </div>
                </div>
            </form>
        </div>;
    }
}

export default withParamsAndNavigate(ItemView);