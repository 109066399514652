import React from "react";
import Address from "./Address";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import Icon from "./Icon";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import L from "./Lang";

class Shipping extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: this.props.item ? this.props.item : {}
        };
        this.uploadRef = React.createRef();
    }

    async componentDidMount() {
        await this.fillListAsync('/api/customer/sa/list', 'saList');
        await this.fillListAsync('/api/terr/list', 'terrList');
        await this.fillListAsync('/api/tax/list', 'taxList');
    }

    componentDidUpdate(prevProps) {
        if (this.props.item !== prevProps.item)
            this.setState({ info: this.props.item });
    }

    copyFromCustomer = () => {
        let item = { ...this.props.item };
        for(let k in this.props.customer) {
            if(k === '__pk_id') continue;
            if(!item[k] && this.props.customer[k])
                item[k] = this.props.customer[k];
        }
        item.ship_name = item.cust_name;
        this.props.onChange?.(item);
    }

    onChange = () => {
        this.props.onChange?.(this.props.item);
    }

    captureTaxEvidenceRead = (file) => {
        var reader = new FileReader();
        reader.onload = () => {
            var p = reader.result.split(',');
            let b64 = p[1];
            this.props.item.tax.imageBase64Content = b64;
            this.props.item.tax.imageType = file.type;
            this.onChange();
            this.setState({ taxEvidenceImageData: reader.result });
        };
        reader.readAsDataURL(file);
    }

    captureTaxEvidence = (e) => {
        for(let n = 0; n < e.target.files.length; n++) {
            let file = e.target.files[n];
            this.captureTaxEvidenceRead(file);
        }
    }

    render() {
        if(!this.props.item) return;
        const tax01 = this.state.taxList?.find(a => a.tax_grp === 'TAX01');
        const tax02 = this.state.taxList?.find(a => a.tax_grp === 'TAX02');
        //const tax03 = this.state.taxList?.find(a => a.tax_grp === 'TAX03');
        return <>
            <div className="row">
                <div className="col-md">
                    <Card title="Shipping" headerButtons={!this.props.disabled && <div className="d-flex gap-1 justify-content-end">
                        {this.props.idx === 0 && <button type="button" className="btn btn-primary" onClick={this.copyFromCustomer}><Icon icon="faCopy" /> <L>Copy from Customer</L></button>}
                        {this.props.onRemove && <button type="button" className="btn btn-danger" onClick={() => this.props.onRemove(this.props.item)}><Icon icon="faTrash" /></button>}
                    </div>}>
                        <FormInput model={this.props.item} name="ship_num" label="Location #" type="text" labelAsPlaceholder onChange={this.onChange} disabled={this.props.disabled} preAddon="#" maxLength={10} required />
                        <FormInput model={this.props.item} name="ship_name" label="Location Name" type="text" labelAsPlaceholder onChange={this.onChange} disabled={this.props.disabled} maxLength={40} required />
                    </Card>
                    <Address item={this.props.item} labelAsPlaceholder onChange={this.onChange} title="Shipping Address" disabled={this.props.disabled} />
                    <Card title="Group">
                        {this.state.saList ? <FormInput model={this.props.item} name="sa_cust" label="S/A" type="select" keyName="sa_cust" options={this.state.saList} labelAsPlaceholder disabled={this.props.disabled} required /> : <LoadingAlert />}
                        {this.state.terrList ? <FormInput model={this.props.item} name="terr_code" label="Territory" type="select" keyName="terr_code" options={this.state.terrList} labelAsPlaceholder disabled={this.props.disabled} required /> : <LoadingAlert />}
                    </Card>
                </div>
                <div className="col-md">
                    <Card title="Shipping Contact">
                        <FormInput model={this.props.item} name="phone" label="Phone" type="text" labelAsPlaceholder onChange={this.onChange} disabled={this.props.disabled} maxLength={20} required />
                        <FormInput model={this.props.item} name="fax" label="Fax" type="text" labelAsPlaceholder onChange={this.onChange} disabled={this.props.disabled} maxLength={20} />
                        <FormInput model={this.props.item} name="email" label="Email" type="email" labelAsPlaceholder onChange={this.onChange} disabled={this.props.disabled} maxLength={40} />
                    </Card>
                    {this.state.taxList && <Card title="Tax">
                        <div className="row">
                            {tax01 && <div className="col-md">
                                <FormInput model={this.props.item} name="tax01" label={tax01.desc_1} type="switch" labelAsPlaceholder disabled={this.props.disabled} />
                            </div>}
                            {tax02 && <div className="col-md">
                                <FormInput model={this.props.item} name="tax02" label={tax02.desc_1} type="switch" labelAsPlaceholder disabled={this.props.disabled} />
                            </div>}
                        </div>
                    </Card>}
                    {this.props.item.tax && <Card title="Tax Details">
                        <input ref={this.uploadRef} type="file" capture="camera" accept="image/*" style={{ display: 'none' }} onChange={this.captureTaxEvidence} />
                        <FormInput model={this.props.item.tax} name="tax_references" label="Reference ID" type="text" disabled={this.props.disabled} onChange={this.onChange} maxLength={15} />
                        <FormInput model={this.props.item.tax} name="tax_expired" label="Expires" type="date" disabled={this.props.disabled} onChange={this.onChange} />
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => this.uploadRef.current?.click()}
                            disabled={this.props.disabled}>
                                <Icon icon="faUpload" /> <L>Upload Tax Evidence</L>
                        </button>
                        {this.state.taxEvidenceImageData
                            && <div>
                                <img src={this.state.taxEvidenceImageData} alt="" className="img-fluid img-thumbnail" />
                            </div>}
                    </Card>}
                </div>

            </div>

        </>;
    }
}

export default withParamsAndNavigate(Shipping);