import BaseCom from "./BaseCom";
import './AppTopTools.css';

export default class AppTopTools extends BaseCom {
    render() {
        return (
            <>
                <div className="app-top-tools">
                    {this.props.children}
                </div>
                <div className="app-top-tools-offset"></div>
            </>
        )
    }
}