import { useNavigate, useParams } from "react-router-dom";
import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import DataTable from "./DataTable";
import L from "./Lang";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import LoadingAlert from "./LoadingAlert";
import { Pop } from "./PopModal";
import AppFormActions from "./AppFormActions";
import Icon from "./Icon";
import { PrintGlobal } from "./PrintView";

export class EODReport extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            totals: []
        };
        this.load = this.load.bind(this);
        this.closeTransaction = this.closeTransaction.bind(this);
    }

    componentDidMount() {
        this.load(this.props.dt, this.props.isClosed);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.batchID !== this.props.batchID || prevProps.isClosed !== this.props.isClosed) {
            this.load();
        }
    }

    load() {

        if (this.props.batchID) {
            qreq.get('/api/eod/batch/' + this.props.batchID, j => {
                if (j.item) {
                    this.setState({ data: j.item });
                    this.calcTotals(j.item);
                }
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback);
            this.setState({ isClosed: true });
        }
        else {
            qreq.get('/api/eod/current?dt=' + Globals.toISOLocalDateOnlyString(new Date()), j => {
                if (j.item) {
                    this.setState({ data: j.item });
                    this.calcTotals(j.item);
                }
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback);
            this.setState({ isClosed: false });
        }
    }

    checkIsClosed = (callback) => {
        qreq.get('/api/visit/eod/close/check?dt=' + (this.props.dt ?? Globals.toISOLocalDateOnlyString(this.dt)), j => {
            if (j.errorCode === 0 && j.item) {
                this.setState({ isClosed: true });
                if (callback) callback(this.props.dt, true);
            }
            else {
                this.setState({ isClosed: false });
                if (callback) callback(this.props.dt, true);
            }
        }, this.unkownErrorCallback);
    }

    closeTransaction() {
        Pop.showConfirm(<L>Are you sure you want to close the transaction?</L>, <L>Close Transaction</L>, () => {
            qreq.post('/api/eod/close?dt=' + Globals.toISOLocalDateOnlyString(new Date()), {}, j => {
                if (j.errorCode === 0) {
                    Pop.show('Success', 'Transaction closed');
                    this.props.navigate('/console/eod/' + j.item.batch_id);
                }
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback);
        });
    }

    calcTotals = (inputData) => {
        if (!inputData) inputData = this.state.data;
        var data = { bultos: 0, invoice: 0, collected: 0, balance: 0 };
        if (inputData) {
            data.bultos = 0;
            data.invoice = inputData.cash.reduce((a, b) => a + b.total_invoice, 0)
                + inputData.credit.reduce((a, b) => a + b.total_invoice, 0);
            data.collected = inputData.cash.reduce((a, b) => a + b.collected, 0)
                + inputData.credit.reduce((a, b) => a + b.collected, 0)
                + inputData.aged.reduce((a, b) => a + b.collected, 0);
            data.balance = inputData.cash.reduce((a, b) => a + b.balance, 0)
                + inputData.credit.reduce((a, b) => a + b.balance, 0)
                + inputData.aged.reduce((a, b) => a + b.balance, 0);
        }
        this.setState({ totals: [data] });
    }

    isClosable = () => {
        return this.state.data?.cash?.length > 0 && this.state.data?.cash?.every(i => i.isReadyToClose)
                && this.state.data?.credit?.length > 0 && this.state.data?.credit?.every(i => i.isReadyToClose);
    }

    render() {
        if (!this.state.data)
            return <LoadingAlert />;
        return <div className="container-fluid mobile-small-tables">
            <h2><L>Cash</L></h2>
            {!this.props.noActions && <AppFormActions showBackButton>
                {this.state.isClosed ? <div className="d-flex justify-content-end align-items-center">
                    <div className="p-2"><L>Already closed.</L></div>
                    <button type="button" className="btn btn-primary btn-lg"
                        onClick={() => PrintGlobal.show(Globals.serverUrl + '/api/receipt/eod/html/null/' + this.props.batchID + '.html')}>
                        <Icon icon="faReceipt" /> <span className="d-none d-md-inline"><L>Print Receipt</L>
                        </span>
                    </button>
                </div>
                : <button type="button" className="btn btn-primary btn-lg" onClick={this.closeTransaction} disabled={!this.isClosable()}>
                    <L>Close Transaction</L>
                </button>}
            </AppFormActions>}
            <DataTable columns={[
                {
                    name: 'trans_num',
                    title: <L>Trans #</L>,
                    width: '20%',
                    valueFunc: row => row.isReadyToClose ? <span>{row.trans_num}</span> : <span className="text-danger">{row.trans_num}</span>
                },
                {
                    name: 'ship_name',
                    title: <L>Shipping</L>,
                    valueFunc: row => row.isReadyToClose ? <span>{row.ship_name}</span> : <span className="text-danger">{row.ship_name}</span>
                },
                {
                    name: 'total_invoice',
                    title: <L>Invoice</L>,
                    valueFunc: row => row.isReadyToClose ? Globals.formatPrice(row.total_invoice) : <span className="text-danger">{Globals.formatPrice(row.total_invoice)}</span>,
                    width: '15%',
                    className: 'text-end'
                },
                {
                    name: 'collected',
                    title: <L>Collected</L>,
                    valueFunc: row => row.isReadyToClose ? Globals.formatPrice(row.collected) : <span className="text-danger">{Globals.formatPrice(row.collected)}</span>,
                    width: '15%',
                    className: 'text-end'
                },
                {
                    name: 'balance',
                    title: <L>Balance</L>,
                    valueFunc: row => row.isReadyToClose ? Globals.formatPrice(row.balance) : <span className="text-danger">{Globals.formatPrice(row.balance)}</span>,
                    width: '15%',
                    className: 'text-end'
                }
            ]} data={this.state.data.cash} />
            <h2><L>Credit</L></h2>
            <DataTable columns={[
                {
                    name: 'trans_num',
                    title: <L>Trans #</L>,
                    width: '20%',
                    valueFunc: row => row.isReadyToClose ? <span>{row.trans_num}</span> : <span className="text-danger">{row.trans_num}</span>
                },
                {
                    name: 'ship_name',
                    title: <L>Shipping</L>,
                    valueFunc: row => row.isReadyToClose ? <span>{row.ship_name}</span> : <span className="text-danger">{row.ship_name}</span>
                },
                {
                    name: 'total_invoice',
                    title: <L>Invoice</L>,
                    valueFunc: row => row.isReadyToClose ? Globals.formatPrice(row.total_invoice) : <span className="text-danger">{Globals.formatPrice(row.total_invoice)}</span>,
                    width: '15%',
                    className: 'text-end',
                },
                {
                    name: 'collected',
                    title: <L>Collected</L>,
                    valueFunc: row => row.isReadyToClose ? Globals.formatPrice(row.collected) : <span className="text-danger">{Globals.formatPrice(row.collected)}</span>,
                    width: '15%',
                    className: 'text-end'
                },
                {
                    name: 'balance',
                    title: <L>Balance</L>,
                    valueFunc: row => row.isReadyToClose ? Globals.formatPrice(row.balance) : <span className="text-danger">{Globals.formatPrice(row.balance)}</span>,
                    width: '15%',
                    className: 'text-end'
                }
            ]} data={this.state.data.credit} />
            <h2><L>Aged</L></h2>
            <DataTable columns={[
                {
                    name: 'trans_num',
                    title: <L>Trans #</L>,
                    width: '20%',
                    valueFunc: row => <span>{row.trans_num}</span>
                },
                {
                    name: 'cust_name',
                    title: <L>Customer</L>
                },
                {
                    name: 'invoice',
                    title: <L>Invoice</L>,
                    valueFunc: row => Globals.formatPrice(row.total_invoice),
                    width: '15%',
                    className: 'text-end text-secondary'
                },
                {
                    name: 'collected',
                    title: <L>Collected</L>,
                    valueFunc: row => Globals.formatPrice(row.collected),
                    width: '15%',
                    className: 'text-end'
                },
                {
                    name: 'balance',
                    title: <L>Balance</L>,
                    valueFunc: row => Globals.formatPrice(row.balance),
                    width: '15%',
                    className: 'text-end'
                }
            ]} data={this.state.data.aged} />
            <h2><L>Totals</L></h2>
            <DataTable columns={[
                {
                    name: 'bultos',
                    title: <L>Bultos</L>,
                    valueFunc: row => this.state.data.bultos,
                    className: 'text-end'
                },
                {
                    name: 'invoice',
                    title: <L>Invoice</L>,
                    valueFunc: row => Globals.formatPrice(row.invoice),
                    width: '15%',
                    className: 'text-end'
                },
                {
                    name: 'collected',
                    title: <L>Collected</L>,
                    valueFunc: row => Globals.formatPrice(row.collected),
                    width: '15%',
                    className: 'text-end'
                },
                {
                    name: 'balance',
                    title: <L>Balance</L>,
                    valueFunc: row => Globals.formatPrice(row.balance),
                    width: '15%',
                    className: 'text-end'
                }
            ]} data={this.state.totals} />
        </div>;
    }
}

class EOD extends BaseCom {

    componentDidMount() {
        NavMenuGlobal.setPath('EOD');
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    render() {
        return <EODReport dt={this.props.params?.dt} batchID={this.props.params?.batchID} isClosed={this.props.params?.dt} navigate={this.props.navigate} />;
    }
}

export function EODReportWithParams(props) {
    return <EODReport params={useParams()} navigate={useNavigate()} noActions={props.noActions} />;
}

export default withParamsAndNavigate(EOD);